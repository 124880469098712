import { render, staticRenderFns } from "./ScrollToDiv.vue?vue&type=template&id=2a9d3894&"
import script from "./ScrollToDiv.vue?vue&type=script&lang=js&"
export * from "./ScrollToDiv.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a9d3894')) {
      api.createRecord('2a9d3894', component.options)
    } else {
      api.reload('2a9d3894', component.options)
    }
    module.hot.accept("./ScrollToDiv.vue?vue&type=template&id=2a9d3894&", function () {
      api.rerender('2a9d3894', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-82BGO0zC7H42Z/JS/comp/ScrollToDiv.vue"
export default component.exports